const MAIN_URL = 'https://george-fx.github.io/nuton_api/';
const MAIN_K_URL = 'https://k.devpv.pl/';


export const GET_COUPONS = `${MAIN_URL}api/coupons.json`;
export const GET_IMAGE = `${MAIN_K_URL}static/img/`;
export const GET_URL_STATIC = `${MAIN_K_URL}static/`;
export const GET_COURSES = `${MAIN_K_URL}story_list`;
export const GET_API = `${MAIN_K_URL}`;
export const GET_BANNERS = `${MAIN_K_URL}story_list`;
export const GET_CATEGORIES = `${MAIN_K_URL}tags`;

export const URLS = {
  GET_IMAGE,
  GET_URL_STATIC,
  GET_BANNERS,
  GET_API,
  GET_COURSES,
  GET_COUPONS,
  GET_CATEGORIES,
};


