import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const VideoSvg: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={17}
      height={17}
      fill='none'
    >
      <g>
        <path
          fill='#8774FE'
          fillRule='evenodd'
          d='M1.471.01a2.29 2.29 0 0 0-.697.285c-.322.213-.542.5-.69.898L0 1.423v9.611l.084.23c.108.295.286.564.496.75.202.18.594.373.858.421.131.025 2.523.034 7.179.027l6.982-.01.203-.077c.272-.104.561-.3.741-.502.178-.2.371-.592.42-.852.05-.262.05-9.322 0-9.584a2.147 2.147 0 0 0-.42-.852 2.032 2.032 0 0 0-.74-.502l-.204-.078L8.579 0C4.72-.002 1.52.003 1.471.01Zm14.03 1.187a.681.681 0 0 1 .25.22l.096.143v9.338l-.095.143a.683.683 0 0 1-.25.22l-.156.075H1.635l-.155-.075a.683.683 0 0 1-.25-.22l-.096-.143V1.56l.095-.143a.709.709 0 0 1 .248-.219l.154-.076h13.715l.155.075ZM6.557 2.872c-.144.065-.292.247-.318.388-.01.063-.016 1.441-.01 3.064.01 2.916.011 2.95.084 3.048.137.184.252.244.47.244h.2l2.323-1.45c1.277-.796 2.373-1.501 2.435-1.566.2-.207.2-.535 0-.742-.062-.065-1.156-.768-2.431-1.564-2.046-1.276-2.338-1.447-2.482-1.458a.66.66 0 0 0-.271.036Zm2.37 2.524c.715.448 1.3.823 1.3.833 0 .014-2.232 1.427-2.735 1.73l-.136.083V4.416l.136.082c.074.045.72.449 1.435.898Zm2.325 7.696c-.606.147-1.184.648-1.385 1.2l-.054.147-4.735.018c-4.714.017-4.736.018-4.834.09-.186.14-.244.253-.244.477 0 .224.058.338.244.477.098.072.12.073 4.826.09l4.728.018.147.296a1.965 1.965 0 0 0 3.352.286 2.14 2.14 0 0 0 .213-.38l.082-.202 1.187-.018c1.134-.016 1.191-.02 1.287-.091.17-.126.26-.29.26-.476 0-.185-.09-.35-.26-.475-.096-.071-.153-.075-1.287-.092l-1.187-.018-.08-.196a2.053 2.053 0 0 0-.873-.97 2.128 2.128 0 0 0-1.387-.18Zm.86 1.193a.845.845 0 1 1-.85 1.46.85.85 0 0 1-.309-1.094.834.834 0 0 1 1.16-.366Z'
          clipRule='evenodd'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path
            fill='#fff'
            d='M0 0h17v17H0z'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
