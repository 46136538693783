import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const DownloadSvg: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={17}
      height={17}
      fill='none'
    >
      <g>
        <path
          fill='#8774FE'
          fillRule='evenodd'
          d='M8.284.035a.57.57 0 0 0-.28.297c-.024.065-.035 1.606-.035 5v4.907L6.947 9.217C5.84 8.109 5.814 8.09 5.55 8.16a.525.525 0 0 0-.354.745c.028.058.719.773 1.536 1.588 1.28 1.279 1.505 1.49 1.627 1.525.3.088.271.11 1.909-1.525.817-.815 1.508-1.53 1.535-1.588a.525.525 0 0 0-.352-.745c-.267-.071-.292-.052-1.39 1.048l-1.013 1.014-.017-4.948C9.015.342 9.014.327 8.946.235A.925.925 0 0 0 8.791.08c-.11-.083-.375-.105-.507-.044ZM.357 11.365c-.163.06-.216.106-.293.258-.06.119-.064.213-.064 1.882 0 1.64.004 1.774.066 2.013.093.365.245.63.515.9s.536.423.9.516C1.732 17 1.874 17 8.5 17s6.768-.001 7.02-.066c.363-.093.63-.245.9-.515s.421-.536.514-.9c.062-.24.066-.373.066-2.014 0-1.682-.003-1.762-.065-1.885-.183-.358-.712-.378-.913-.034-.065.11-.068.18-.084 1.909-.016 1.699-.02 1.801-.082 1.926a1.07 1.07 0 0 1-.434.434c-.13.064-.237.065-6.922.065s-6.792 0-6.922-.065a1.069 1.069 0 0 1-.434-.434c-.061-.125-.066-.227-.081-1.926-.015-1.617-.023-1.804-.074-1.899a.543.543 0 0 0-.632-.23Z'
          clipRule='evenodd'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path
            fill='#fff'
            d='M0 0h17v17H0z'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
