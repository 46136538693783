import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { hooks } from '../hooks';
import { custom } from '../custom';
import { components } from '../components';
import { Card, CardContent, Checkbox, Divider, Typography, Input } from '@mui/material';
import { text } from '../text';
import { URLS } from '../config';

export const AddANewCard: React.FC = () => {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const location = useLocation();
  const { pathname } = useLocation();
  const [code, setCode] = useState('');
  const [isActivated, setIsActivated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
      document.documentElement.scrollTop = 0;
    };

    scrollToTop();
  }, [pathname]);

  const navigate = hooks.useNavigate();

  const renderImageBackground = (): JSX.Element => {
    return <components.Background version={1} />;
  };

  const renderHeader = (): JSX.Element => {
    return (
      <components.Header
        title='Zakup'
        goBack={true}
      />
    );
  };

  const renderContent = (): JSX.Element => {
    return (
      <main
        style={{ paddingBottom: 0 }}
        className='container'
      >
        <Card sx={{ minWidth: 275, marginBottom: "20px", marginTop: "20px", borderRadius: "16px", background: 'linear-gradient(45deg, #FFF 30%, #FF8FBB 140%)' }}>
          <CardContent>
            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
            </Typography>
            <Typography sx={{ color: 'text.secondary' }} variant="h5" component="div">
              Pełny dostęp na rok
            </Typography>
            <Typography variant="h4" component="div">
              za 36,5zł
            </Typography>
            <text.T14>
              (Tylko <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>10 groszy</span> dziennie!)
            </text.T14>
          </CardContent>
        </Card>

        <custom.InputField
          label='E-mail'
          placeholder='moj@email.pl'
          containerStyle={{ marginBottom: 20 }}
        />
        
        <text.T14 style={{ marginBottom: '20px' }}> <Checkbox {...label} color="default" />Akceptuję regulamin</text.T14>
        <components.Button
          title='Przechodzę do płatności'
          onClick={() => {
            navigate(-1);
          }}
          style={{ marginBottom: 20 }}
        />

        <Divider style={{ marginTop: 30 }} />
        <text.T18 style={{ marginTop: 20, marginBottom: 10 }}>
          Mam już kod
        </text.T18>
        <text.T14 style={{ marginBottom: 10 }}>
          Wpisz kod, jeśli otrzymałeś go na e-mail. Kod jest potrzebny do aktywacji subskrybcji.
        </text.T14>
        <Input style={{ marginBottom: 10 }} placeholder='Wpisz kod' onChange={(e) => setCode(e.target.value)} />

        {isActivated ? (
          <>
            <Typography variant="h6" color="success" style={{ marginTop: 10 }}>
              Udało się. To urządzenie zostało dodane.
            </Typography>
            <components.Button
              title='Zaczynamy!'
              onClick={() => navigate(-1)}
              style={{ marginBottom: 20 }}
            />
          </>
        ) : (
          code.length >= 6 && (
            <components.Button
              title='Aktywuj aplikację'
              onClick={async () => {
                const token = localStorage.getItem('token_rido');
                if (token) {
                  try {
                    const response = await fetch(`${URLS.GET_API}check_code/${code}?token=${token}`, {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                      }
                    });

                    if (response.ok) {
                      // Pomyślna aktywacja
                      setIsActivated(true);
                      setErrorMessage('');
                    } else  {
                      const errorData = await response.json();
                      
                        setErrorMessage(errorData.message);
                     
                    } 
                  } catch (error) {
                    console.error('Error:', error);
                    setErrorMessage('Błąd sieci. Spróbuj ponownie później.');
                  }
                } else {
                  console.error('No token found');
                  setErrorMessage('Nie znaleziono tokena');
                }
              }}
              style={{ marginBottom: 20 }}
            />
          )
        )}

        {errorMessage && (
          <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
            {errorMessage}
          </Typography>
        )}
      </main>
    );
  };

  return (
    <>
      {renderImageBackground()}
      {renderHeader()}
      {renderContent()}
    </>
  );
};
