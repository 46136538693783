import 'reset-css';
import 'swiper/css';
import './scss/index.scss';
import {store} from './store/store';
import {Provider} from 'react-redux';
import {StackNavigator} from './navigation/StackNavigator';
import { useEffect } from 'react';

function generateRandomKey() {
  let randomKey = '';
  for (let i = 0; i < 3; i++) {
    randomKey += Math.random().toString(36).substr(2); // Dodaj kolejne losowe stringi
  }
  return randomKey;
}

function App() {
  useEffect(() => {
    const storedKey = localStorage.getItem('token_rido');
    if (!storedKey) {
      const randomKey = generateRandomKey();
      localStorage.setItem('token_rido', randomKey);
      
    } else {
      
    }
  }, []);

  return (
    <div>
      <Provider store={store}>
        <StackNavigator />
      </Provider>
    </div>
  );
}

export default App;
