import React, { useState, useEffect } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

interface Segment {
  start: number;
  end: number;
  text: string;
}

interface TranscriptHighlighterProps {
  segments: Segment[];
  audioSrc: string;
}

const TranscriptHighlighter: React.FC<TranscriptHighlighterProps> = ({  segments, audioSrc }) => {
  const [currentTime, setCurrentTime] = useState<number>(0);

  const handleTimeUpdate = (e: Event) => {
    const target = e.target as HTMLAudioElement;
    setCurrentTime(target.currentTime);
  };

  const getHighlightedText = () => {
    return segments.map((segment, index) => {
      const isHighlighted = currentTime >= segment.start && currentTime <= segment.end;
      return (
        <span
          key={index}
          style={{
            backgroundColor: isHighlighted ? 'yellow' : 'transparent',
            fontWeight: isHighlighted ? 'bold' : 'normal',
          }}
        >
          {segment.text}{' '}
        </span>
      );
    });
  };

  return (
    <div>
      <AudioPlayer
        src={audioSrc}
        hasDefaultKeyBindings={false}
        customVolumeControls={[]}
        customAdditionalControls={[]}
        onListen={handleTimeUpdate}
        showJumpControls={false}
        layout="horizontal-reverse"
        onLoadedMetaData={handleTimeUpdate}
        listenInterval={500} // Zmiana co 500 ms
      />
      <div className="transcript">
        {getHighlightedText()}
      </div>
    </div>
  );
};

export default TranscriptHighlighter;
