import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { List, ListItem } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import {hooks} from '../hooks';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

function IconE() {
    return (
        <CheckIcon sx={{ color: '#3e8155', marginRight:"8px" }} />
    );
    }

export default function BuyFullAccess() {
    const navigate = hooks.useNavigate();
  return (
    <Card sx={{ minWidth: 275, margin: "20px", borderRadius: "16px", background: 'linear-gradient(45deg, #FFF 30%, #FF8FBB 140%)' }}>
      <CardContent>
        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
         W tej bajce jest jeszcze dalsza historia ...
        </Typography>
        <Typography variant="h5" component="div">
        Pełny dostęp na rok za 36,5zł 
        </Typography>
        <List>
            <ListItem><IconE />  Dostęp do 1 000 bajek i opowiadań </ListItem>
            <ListItem><IconE />  Ponad 3 000 słówek</ListItem>
            <ListItem><IconE />  Łatwe przyswajanie słówek dzięki wysokiej częstotliwości wystąpień</ListItem>
            <ListItem><IconE />  Zapamiętuj słówka dzieki metodzie RIDO polegającej na skojarzeniach i umiejscowieniu słów w historyjce</ListItem>
            <ListItem><IconE />  Codziennie nowe opowiadania</ListItem>
            <ListItem><IconE />  Dziecko łączy przyjemne z pożytecznym</ListItem>
            <ListItem><IconE />  Dzięki RidoKids dziecko rozwija wyobraźnie słuchając bajek</ListItem>
            <ListItem><IconE />  Brak automatycznego odnowienia subskrybcji </ListItem>
            <ListItem><IconE />  Możesz używać na dwóch urządzeniach </ListItem>
            <ListItem>... i to wszystko za 10 groszy dziennie! </ListItem>
        </List>
        
      </CardContent>
    <CardActions sx={{ justifyContent: 'center' }}>
        <Button size="large" variant="contained" fullWidth sx={{ backgroundColor: '#e99ba7',margin:'20px' }}
        onClick={() => {
            navigate('/add-a-new-card');
          }}
        >Kupuję</Button>
    </CardActions>
    </Card>
  );
}