import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const GoogleSvg: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={25}
      height={16}
      fill='none'
    >
      <path
        fill='#DD4B39'
        d='M7.786 6.345h7.267a8.758 8.758 0 0 1-.17 4.088c-.379 1.287-1.041 2.366-1.987 3.237-.87.795-1.93 1.343-3.18 1.646-1.363.303-2.687.284-3.974-.057A7.202 7.202 0 0 1 2.96 13.84a7.535 7.535 0 0 1-2.101-2.555C.026 9.695-.201 8.011.177 6.232c.114-.682.341-1.325.682-1.93C1.767 2.408 3.225 1.121 5.23.44c1.74-.605 3.482-.586 5.223.057.909.34 1.741.852 2.498 1.533a3.322 3.322 0 0 1-.397.454c-.19.152-.303.265-.34.341-.152.114-.398.34-.739.681-.303.303-.53.55-.681.739a3.96 3.96 0 0 0-1.703-1.022 4.211 4.211 0 0 0-2.272-.057 4.525 4.525 0 0 0-2.327 1.249 5.527 5.527 0 0 0-1.136 1.874c-.34.984-.34 1.987 0 3.009.34.984.946 1.779 1.817 2.384.53.379 1.098.625 1.703.739.568.113 1.193.113 1.874 0a4.248 4.248 0 0 0 1.703-.682c.87-.568 1.382-1.362 1.533-2.384H7.786v-3.01Zm16.465.17v1.931h-2.668v2.612h-1.93V8.446h-2.67v-1.93h2.67V3.847h1.93v2.669h2.668Z'
      />
    </svg>
  );
};
