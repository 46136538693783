import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const Rating: React.FC = () => {
  return (
    <div>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={10}
        height={10}
        fill='none'
        style={{marginRight: 3}}
      >
        <path
          fill='#FFC700'
          d='m5.167 1 1.287 2.608 2.88.421L7.25 6.06l.492 2.866-2.575-1.354-2.575 1.354.491-2.867L1 4.03l2.88-.42L5.166 1Z'
        />
      </svg>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={10}
        height={10}
        fill='none'
        style={{marginRight: 3}}
      >
        <path
          fill='#FFC700'
          d='m5.167 1 1.287 2.608 2.88.421L7.25 6.06l.492 2.866-2.575-1.354-2.575 1.354.491-2.867L1 4.03l2.88-.42L5.166 1Z'
        />
      </svg>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={10}
        height={10}
        fill='none'
        style={{marginRight: 3}}
      >
        <path
          fill='#FFC700'
          d='m5.167 1 1.287 2.608 2.88.421L7.25 6.06l.492 2.866-2.575-1.354-2.575 1.354.491-2.867L1 4.03l2.88-.42L5.166 1Z'
        />
      </svg>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={10}
        height={10}
        fill='none'
        style={{marginRight: 3}}
      >
        <path
          fill='#FFC700'
          d='m5.167 1 1.287 2.608 2.88.421L7.25 6.06l.492 2.866-2.575-1.354-2.575 1.354.491-2.867L1 4.03l2.88-.42L5.166 1Z'
        />
      </svg>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={10}
        height={10}
        fill='none'
      >
        <path
          fill='#FFC700'
          d='m5.167 1 1.287 2.608 2.88.421L7.25 6.06l.492 2.866-2.575-1.354-2.575 1.354.491-2.867L1 4.03l2.88-.42L5.166 1Z'
        />
      </svg>
    </div>
  );
};
