import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {Swiper, SwiperSlide} from 'swiper/react';
import * as Accordion from '@radix-ui/react-accordion';
import ReactAudioPlayer from 'react-audio-player';
import Tooltip from '@mui/material/Tooltip';

import {text} from '../text';
import {URLS} from '../config';
import {hooks} from '../hooks';
import {utils} from '../utils';
import {items} from '../items';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {components} from '../components';
import {course as elements} from '../course';
import { Skeleton, Typography } from '@mui/material';
import 'react-h5-audio-player/lib/styles.css';
import TranscriptHighlighter from '../components/TranscriptHighlighter';
import AudioPlayer from 'react-h5-audio-player';
import BuyFullAccess from '../components/BuyFullAccess';




const lessons = [
  {
    id: 1,
    title: '01. Introduction',
    lecture: '3 lectures',
    duration: '7 min',
    content: [
      {
        id: 1,
        title: 'Setting up individual Camera Rigs',
        duration: '00:44',
      },
      {
        id: 2,
        title: 'Setting Up VRTKs Tracked Alias',
        duration: '03:49',
      },
      {
        id: 3,
        title: 'Setting Up VRTKs Tracked Alias',
        duration: '02:22',
        locked: true,
      },
    ],
  },
  {
    id: 2,
    title: '02. Movement in VR',
    lecture: '8 lectures',
    duration: '1h 33 min',
    content: [
      {
        id: 1,
        title: 'Setting up individual Camera Rigs',
        duration: '00:44',
      },
      {
        id: 2,
        title: 'Setting Up VRTKs Tracked Alias',
        duration: '03:49',
      },
      {
        id: 3,
        title: 'Setting Up VRTKs Tracked Alias',
        duration: '02:22',
        locked: true,
      },
    ],
  },
  {
    id: 3,
    title: '03. Distance Grabber',
    lecture: '8 lectures',
    duration: '1h 33 min',
    content: [
      {
        id: 1,
        title: 'Setting up individual Camera Rigs',
        duration: '00:44',
      },
      {
        id: 2,
        title: 'Setting Up VRTKs Tracked Alias',
        duration: '03:49',
      },
      {
        id: 3,
        title: 'Setting Up VRTKs Tracked Alias',
        duration: '02:22',
        locked: true,
      },
    ],
  },
  {
    id: 4,
    title: '04. Snap Zones',
    lecture: '8 lectures',
    duration: '1h 33 min',
    content: [
      {
        id: 1,
        title: 'Setting up individual Camera Rigs',
        duration: '00:44',
      },
      {
        id: 2,
        title: 'Setting Up VRTKs Tracked Alias',
        duration: '03:49',
      },
      {
        id: 3,
        title: 'Setting Up VRTKs Tracked Alias',
        duration: '02:22',
        locked: true,
      },
    ],
  },
];

const tabs = [
  {
    id: 1,
    name: 'Bajka',
  },
  {
    id: 2,
    name: 'Słówka',
  },
  {
    id: 3,
    name: 'Podobne kursy',
  },
  {
    id: 4,
    name: 'Słówka',
  },
];

const reviews = [
  {
    id: 1,
    name: 'Lillian Daviss',
    rating: 5,
    date: 'March 3, 2022',
    comment: 'Lots of good info.',
    avatar: 'https://george-fx.github.io/nuton_api/assets/users/02.jpg',
  },
  {
    id: 2,
    name: 'Adam Thompson',
    rating: 5,
    date: 'March 28, 2022',
    comment: 'Great course!',
    avatar: 'https://george-fx.github.io/nuton_api/assets/users/03.jpg',
  },
  {
    id: 3,
    name: 'Ryan Howard',
    rating: 5,
    date: 'February 12, 2022',
    comment: 'It was a great course.',
    avatar: 'https://george-fx.github.io/nuton_api/assets/users/04.jpg',
  },
];

export const CourseDetails: React.FC = () => {
  const location = useLocation();
  const {pathname} = useLocation();

  const navigate = hooks.useNavigate();

  const course = location.state.course;
  const [count, setCount] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [coursesData, setCoursesData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openItem, setOpenItem] = useState<string | null>(null);
  const [dataWord, setDataWord] = useState<any>(null); // Add this line to define setData

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${URLS.GET_API}words_document/${course.id}`);
        const result = await response.json();
        setDataWord(result); // Correctly update the state here
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Call the async function
  }, [course.text]); // Add course.text as a dependency to avoid unnecessary re-renders


  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
      document.documentElement.scrollTop = 0;
    };

    scrollToTop();
  }, [pathname]);

  const getData = async () => {
    setLoading(true);

    try {
      const results = await Promise.allSettled([
        axios.get(URLS.GET_COURSES),
        // axios.get(URLS.GET_BANNERS),
      ]);

      const coursesResponse = results[0];

      if (coursesResponse.status === 'fulfilled') {
        setCoursesData(coursesResponse.value.data);
      } else {
        console.error('Error fetching courses:', coursesResponse.reason);
      }

      // const productsResponse = results[0];
      // const bannersResponse = results[1];

      // if (productsResponse.status === 'fulfilled') {
      //   setProductsData(productsResponse.value.data.products);
      // } else {
      //   console.error('Error fetching products:', productsResponse.reason);
      // }

      // if (bannersResponse.status === 'fulfilled') {
      //   setBannersData(bannersResponse.value.data.banners);
      // } else {
      //   console.error('Error fetching banners:', bannersResponse.reason);
      // }
    } catch (error) {
      console.error('Unexpected error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const renderImageBackground = (): JSX.Element => {
    return <components.Background version={1} />;
  };

  const renderPreviewImage = (): JSX.Element => {
    return (
      <div
        style={{marginTop: 20, marginBottom: 20}}
        className='container'
      >
        <img
          src={`${URLS.GET_IMAGE}400/${course.header_img_path}`}
          alt='preview'
          style={{
            width: '100%',
            objectFit: 'cover',
            borderRadius: 10,
          }}
        />
      </div>
    );
  };

  const renderHeader = (): JSX.Element => {
    return (
      <components.Header
        goBack={true}
        course={course}
        addWishlist={true}
      />
    );
  };

  const renderTitle = (): JSX.Element => {
    return (
      <div className='container'>
        <text.H4
          style={{marginBottom: 6}}
          numberOfLines={2}
        >
          {course.name}
        </text.H4>
      </div>
    );
  };

  const renderRating = (): JSX.Element => {
    return (
      <div
        style={{...utils.rowCenter({gap: 6}), marginBottom: 20}}
        className='container'
      >
        <div style={{...utils.rowCenter({gap: 3})}}>
          <text.T10
            style={{
              ...theme.fonts.Lato_700Bold,
              color: '#FFC700',
              marginTop: 3,
            }}
          >
            5.0
          </text.T10>
          <components.Rating />
        </div>
        <text.T10>(149 ratings) 2,719 students</text.T10>
      </div>
    );
  };

  const renderTabs = (): JSX.Element => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 20,
        }}
        className='container'
      >
        {tabs.map((item, index, array) => {
          const isLast = index === array.length - 1;
          return (
            <div
              className={selectedTab.id === item.id ? 'custom-block' : ''}
              key={item.id}
              style={{
                cursor: 'pointer',
                userSelect: 'none',
                ...utils.rowCenter(),
                padding: '14px 10px',
              }}
              onClick={() => setSelectedTab(item)}
            >
              <span
                style={{
                  color:
                    selectedTab.id === item.id
                      ? theme.colors.mainColor
                      : theme.colors.secondaryTextColor,
                  ...theme.fonts.LeagueSpartan_600SemiBold,
                  fontSize: 8,
                  textTransform: 'uppercase',
                }}
              >
                {item.name}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

// Definiujemy interfejs dla propsów
interface BoldTextProps {
  text: string;
}

// Interfejs dla danych z API
interface WordData {
  image: string;
  translation: string;
}

// Zaktualizowana definicja HtmlTooltip, aby akceptować children
const HtmlTooltip = ({ title, children, open }: { title: React.ReactNode; children: React.ReactNode; open: boolean }) => {
  return (
    <Tooltip title={title} arrow open={open}>
      <span style={{ cursor: 'pointer' }}>{children}</span>
    </Tooltip>
  );
};

const BoldText: React.FC<BoldTextProps> = ({ text }): JSX.Element | null => {
  // Stan do zarządzania widocznością tooltipa i aktualnym tekstem
  const [tooltipOpen, setTooltipOpen] = useState<number | null>(null); // Użyj numeru dla identyfikacji tooltipa
  const [wordData, setWordData] = useState<WordData | null>(null); // Stan dla danych z API

  // Funkcja do pobierania danych z API na podstawie słowa
  const fetchWordData = async (word: string) => {
    try {
      const response = await fetch(`${URLS.GET_API}word/${word}`); // Podstawowy endpoint do pobrania danych
      const data = await response.json();
      setWordData({
        image: data.image, // Zakładam, że API zwraca URL obrazka
        translation: data.pl.translate, // Zakładam, że API zwraca tłumaczenie
      });
    } catch (error) {
      console.error("Błąd podczas pobierania danych:", error);
    }
  };

  // Pobieranie danych, gdy tooltip zostaje otwarty
  useEffect(() => {
    if (tooltipOpen !== null) {
      const word = text.split(/(\*\*.*?\*\*)/g)[tooltipOpen]?.slice(2, -2); // Wyciągnięcie pogrubionego tekstu
      if (word) {
        fetchWordData(word); // Wywołanie funkcji pobierającej dane z API
      }
    }
  }, [tooltipOpen]);

  // Funkcja do pogrubiania słów oznaczonych **
  const formatText = (text: string) => {
    // Rozdziel tekst na części, które są pogrubione i nie
    const parts = text.split(/(\*\*.*?\*\*)/g);

    // Funkcja do wyszukiwania klucza po wartości
    const findKeyByValue = (array: Array<any>, value: string) => {
      // Iterujemy przez elementy tablicy
      for (const obj of array) {
        // Iterujemy przez klucze w każdym obiekcie
        for (const key in obj) {
          if (obj[key] === value) {
            return key; // Zwracamy klucz, gdy znajdziemy dopasowanie
          }
        }
      }
      return null; // Zwracamy null, jeśli nie znaleziono dopasowania
    };
    
    return parts.map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        // Usuń oznaczenia i zwróć pogrubiony tekst
        const boldText = part.slice(2, -2); // Usunięcie znaczników
        const foundKey = findKeyByValue(course.words, boldText);
        return (
          <HtmlTooltip
            key={index}
            title={
              <React.Fragment>
              {wordData ? (
                <div style={{ display: 'flex', padding: '10px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '1.6em' }}>
                  <Typography
                    style={{ fontSize: '1.6em', marginBottom: '10px' }}
                    color="inherit"
                  >
                    {foundKey}
                  </Typography>
                  <img
                    src={`${URLS.GET_URL_STATIC}img/400/${wordData.image}.png`} // Obraz z API
                    alt={boldText}
                    style={{ width: '200px', height: 'auto', display: 'block', margin: '5px 0' }}
                  />
                </div>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  {/* Szkielet dla tekstu */}
                  <Skeleton variant="text" width={200} height={30} style={{ marginBottom: '10px' }} />
                  {/* Szkielet dla obrazu */}
                  <Skeleton variant="rectangular" width={200} height={150} />
                </div>
              )}
            </React.Fragment>
            }
            open={tooltipOpen === index} // Tooltip jest otwarty tylko dla aktualnego indeksu
          >
            <span 
              style={{ margin: '0 8px', fontWeight: 'bold', fontSize: '1.2em', textAlign: 'center' }}
              onClick={() => {
                // Przełączanie stanu tooltipa dla aktualnego indeksu
                setTooltipOpen(tooltipOpen === index ? null : index);
              }}
              onMouseLeave={() => setTooltipOpen(null)} // Zamknij tooltip po opuszczeniu myszką
            >
              {boldText}
            </span>
          </HtmlTooltip>
        );
      }
      return (
        <span key={index} style={{ fontSize: '1.0em', lineHeight: '1.7em' }}>
          {part}
        </span>
      );
    });
  };

  return (
    <div style={{ marginBottom: 20 }}>
      {formatText(text)}
    </div>
  );
};





  const renderAboutCourse = (): JSX.Element | null => {

    if (selectedTab.id === 1) {
      return (
        <div
          className='container'
          style={{marginBottom: 20}}
        >
          {/* <text.H5 style={{marginBottom: 10}}>About Course</text.H5>
          <span style={{...utils.rowCenter({gap: 10}), marginBottom: 6}}>
            <svg.UserSvg />
            <text.T16>{course.author}</text.T16>
          </span> */}
          {/* <span style={{...utils.rowCenter({gap: 10}), marginBottom: 6}}>
            <svg.VideoSvg />
            <text.T16>14 hours on-demand video</text.T16>
          </span>
          <span style={{...utils.rowCenter({gap: 10}), marginBottom: 6}}>
            <svg.DownloadSvg />
            <text.T16>16 downloadable resources</text.T16>
          </span>
          <span style={{...utils.rowCenter({gap: 10}), marginBottom: 10}}>
            <svg.CertificateSvg />
            <text.T16>Certificate of completion</text.T16>
          </span> */}
          {/* <audio 
            ref={audioRef} 
            src={`${URLS.GET_URL_STATIC}voice/${course.text_audio_path}`} 
            preload="auto" 
          /> */}
          
          <ReactAudioPlayer
          src={`${URLS.GET_URL_STATIC}voice/${course.header_audio_path}`} 
          autoPlay
          
        />
          <text.H4 style={{marginBottom: 25, marginTop: 25}}>
            {course.title}
          </text.H4>
            <text.T16 style={{marginBottom: 25, fontStyle: 'italic'}}>
            {course.header}
          </text.T16>

          {/* <text.T16 style={{marginBottom: 25, fontStyle: 'italic'}}>
            {course.text_12}
          </text.T16> */}
          <BoldText text={course.text_12 || course.text} /> 
          
          {/* <components.Button
            title='Buy course'
            onClick={() => {
              navigate('/checkout', {state: {course}});
            }}
          /> */}
        </div>
      );
    }

    return null;
  };

  const renderLessons = (): JSX.Element | null => {
    if (selectedTab.id === 2) {
      return (
        <div className='container'>
          {dataWord.words_data?.map((item: any, id: string) => {
            return (
              <div key={item.id}>
                <text.T12 style={{ marginBottom: 10 }}>
                  {item.key} - {item.pl.translate}
                </text.T12>
                <img
                  src={`${URLS.GET_URL_STATIC}img/400/${item.image}.png`}
                  width={50} // Zmniejszono szerokość zdjęcia
                    alt={`${item.key} image`}
                    
                    />
                    <AudioPlayer
                    src={`${URLS.GET_URL_STATIC}voice/${item.key_audio_path}`}
                    hasDefaultKeyBindings={false}
                    customVolumeControls={[]}
                    customProgressBarSection={[]}
                    customAdditionalControls={[]}
                    showJumpControls={false}
                    style={{ width: '100px', backgroundColor: 'rgba(255, 255, 255, 0.5)' }} // Dodano styl dla szerokości i przezroczyste tło
                    />
                  
                    {item.pl.sentence?.map((sentence: any, sentenceId: string) => (
                      <>
                  <text.T12 key={sentenceId} style={{ marginBottom: 10 }}>
                    {sentence.text} - {sentence.translate}
                  </text.T12>
                  <AudioPlayer
                  src={`${URLS.GET_URL_STATIC}voice/${sentence.text_audio_path}`}
                  hasDefaultKeyBindings={false}
                  customVolumeControls={[]}
                  customProgressBarSection={[]}
                  customAdditionalControls={[]}
                  showJumpControls={false}
                  style={{ width: '100px', backgroundColor: 'rgba(255, 255, 255, 0.5)' }} // Dodano styl dla szerokości i przezroczyste tło
                  />
                  <AudioPlayer
                  src={`${URLS.GET_URL_STATIC}voice/${sentence.translate_audio_path}`}
                  hasDefaultKeyBindings={false}
                  customVolumeControls={[]}
                  customProgressBarSection={[]}
                  customAdditionalControls={[]}
                  showJumpControls={false}
                  style={{ width: '100px', backgroundColor: 'rgba(255, 255, 255, 0.5)' }} // Dodano styl dla szerokości i przezroczyste tło
                  />
                  </>
                ))}
              </div>
            );
          })}
        </div>
      );
    }
  
    return null;
  };
  

  const renderReviews = (): JSX.Element | null => {
    if (selectedTab.id === 4) {
      return (
        <div
          style={{marginBottom: 20}}
          className='container'
        >
          {reviews.map((item, index, array) => {
            const isLast = index === array.length - 1;
            return (
              <items.ReviewItem
                key={item.id}
                review={item}
                isLast={isLast}
              />
            );
          })}
        </div>
      );
    }

    return null;
  };

  const renderInstructor = (): JSX.Element | null => {
    if (selectedTab.id === 3) {
      return (
        <div>
          <div
            className='container'
            style={{marginBottom: 30}}
          >
            <text.H5 style={{marginBottom: 2}}>{course.author}</text.H5>
            <text.T10 style={{marginBottom: 10}}>{course.position}</text.T10>
            <div style={{...utils.rowCenter(), marginBottom: 30}}>
              <img
                src={course.authorImage}
                alt='author'
                style={{borderRadius: 5, width: 91, marginRight: 10}}
              />
              <div>
                <div style={{...utils.rowCenter({gap: 7}), marginBottom: 6}}>
                  <svg.StarSvg color={theme.colors.mainColor} />
                  <text.T10 style={{color: theme.colors.bodyTextColor}}>
                    4.5 Instructor Rating
                  </text.T10>
                </div>
                <div style={{...utils.rowCenter({gap: 7}), marginBottom: 6}}>
                  <svg.ChatSvg />
                  <text.T10 style={{color: theme.colors.bodyTextColor}}>
                    116 Reviews
                  </text.T10>
                </div>
                <div style={{...utils.rowCenter({gap: 7}), marginBottom: 6}}>
                  <svg.GraduateSvg />
                  <text.T10 style={{color: theme.colors.bodyTextColor}}>
                    936 Students
                  </text.T10>
                </div>
                <div style={{...utils.rowCenter({gap: 7}), marginBottom: 6}}>
                  <svg.BtnPlaySvg />
                  <text.T10 style={{color: theme.colors.bodyTextColor}}>
                    12 Courses
                  </text.T10>
                </div>
              </div>
            </div>
            <text.H5 style={{marginBottom: 10}}>About teacher</text.H5>
            <text.T16
              style={{color: theme.colors.bodyTextColor, marginBottom: 10}}
            >
              I started working as a software developer at the age of 23, and
              never looked back. I explored careers as varied as being a Game
              Developer, Educator and Drone Pilot, over the last 25 years. None
              of these provided a continual challenge in the same way the
              combination of...
            </text.T16>
            <svg.ShowMoreSvg />
          </div>
          <div style={{marginBottom: 30, width: '100%'}}>
            <components.BlockHeading
              title='Courses'
              containerStyle={{padding: '0 20px', marginBottom: 7}}
              viewAllOnClick={() => {
                navigate('/category-list', {state: {title: 'Courses'}});
              }}
            />
            <Swiper
              spaceBetween={16}
              slidesPerView={'auto'}
              pagination={{clickable: true}}
            >
              {coursesData.map((course: any, index: any, array: any) => {
                const isLast = index === array.length - 1;
                return (
                  <SwiperSlide
                    key={course.id}
                    style={{
                      width: 230,
                      height: 300,
                      padding: 10,
                      cursor: 'pointer',
                      userSelect: 'none',
                      backgroundColor: index % 2 === 0 ? '#AEB7F8' : '#FE9BB3',
                      borderRadius: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative',
                    }}
                    onClick={() => {
                      navigate('/course-details', {state: {course}});
                    }}
                  >
                    <img
                      src={course.threeDPreview}
                      alt={course.name}
                      style={{
                        width: '88%',
                        margin: '0 auto',
                        marginTop: 10,
                        marginBottom: 'auto',
                      }}
                    />
                    <elements.CourseName
                      course={course}
                      numberOfLines={2}
                      style={{color: theme.colors.white, marginBottom: 16}}
                    />
                    <div style={{...utils.rowCenterSpcBtw()}}>
                      <div style={{...utils.rowCenter()}}>
                        <svg.ClockSvg color='#D7D9FE' />
                        <text.T14
                          style={{
                            marginLeft: 6,
                            marginRight: 'auto',
                            color: '#D7D9FE',
                          }}
                        >
                          {course.duration}
                        </text.T14>
                      </div>
                      <text.T16
                        style={{
                          color: theme.colors.white,
                          ...theme.fonts.Lato_700Bold,
                        }}
                      >
                        ${course.price.toFixed(2)}
                      </text.T16>
                    </div>
                    <elements.CourseRating
                      course={course}
                      containerStyle={{
                        position: 'absolute',
                        top: 2,
                        left: 2,
                        borderBottomRightRadius: 10,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                    />
                    <elements.CourseInWishlist
                      course={course}
                      size={20}
                      customFillColor={theme.colors.white}
                      customStrokeColor={theme.colors.white}
                      style={{position: 'absolute', right: 10, top: 10}}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      );
    }

    return null;
  };

  
  
  // Przykład danych (transkrypcja i segmenty)
  const audioSrc = `${URLS.GET_URL_STATIC}voice/${course.text_audio_path}` ;
  const segments = [
    {
      "id": 0,
      "avg_logprob": -0.15625,
      "compression_ratio": 1.4358974695205688,
      "end": 6.0,
      "no_speech_prob": 0.06768633425235748,
      "seek": 0,
      "start": 0.0,
      "temperature": 0.0,
      "text": " W town Kudolandia existed szko\u0142a, jakiej nikt wcze\u015bniej nie widzia\u0142.",
      "tokens": [
          50364,
          343,
          3954,
          591,
          532,
          30063,
          654,
          13135,
          7870,
          4093,
          5024,
          11,
          4207,
          7764,
          297,
          9874,
          40785,
          2838,
          27486,
          8908,
          13,
          50664
      ]
  },
  {
      "id": 1,
      "avg_logprob": -0.15625,
      "compression_ratio": 1.4358974695205688,
      "end": 14.0,
      "no_speech_prob": 0.06768633425235748,
      "seek": 0,
      "start": 6.0,
      "temperature": 0.0,
      "text": " Student si\u0119 tam nie tylko ma tematyki i j\u0119zyka polskiego, ale tak\u017ce jak rozmawia\u0107 z magicznymi stworzeniami.",
      "tokens": [
          50664,
          12464,
          3244,
          7677,
          2838,
          13219,
          463,
          1383,
          21398,
          2984,
          741,
          42309,
          40940,
          28757,
          12200,
          11,
          6775,
          23306,
          4207,
          35234,
          34953,
          2162,
          710,
          5585,
          89,
          31813,
          342,
          28321,
          2904,
          15568,
          13,
          51064
      ]
  },
  {
      "id": 2,
      "avg_logprob": -0.15625,
      "compression_ratio": 1.4358974695205688,
      "end": 22.0,
      "no_speech_prob": 0.06768633425235748,
      "seek": 0,
      "start": 14.0,
      "temperature": 0.0,
      "text": " Ka\u017cdego ranka w klasie pojawia\u0142 si\u0119 nowy temat do nauki, a nauczyciele byli czarodziejami i wr\u00f3\u017ckami.",
      "tokens": [
          51064,
          10988,
          1427,
          67,
          6308,
          6181,
          64,
          261,
          350,
          7743,
          414,
          30655,
          8908,
          3244,
          586,
          88,
          32954,
          360,
          35616,
          2984,
          11,
          257,
          49103,
          1229,
          4260,
          306,
          538,
          2081,
          6472,
          289,
          378,
          19554,
          4526,
          741,
          928,
          812,
          1427,
          48737,
          13,
          51464
      ]
  },
  {
      "id": 3,
      "avg_logprob": -0.15625,
      "compression_ratio": 1.4358974695205688,
      "end": 29.0,
      "no_speech_prob": 0.06768633425235748,
      "seek": 0,
      "start": 22.0,
      "temperature": 0.0,
      "text": " Pewnego Deli zaog\u0142osi\u0142a, \u017ce w szkole odb\u0119dzie si\u0119 wielki konkurs na najlepszy projekt magiczny.",
      "tokens": [
          51464,
          30638,
          11858,
          413,
          10148,
          7949,
          664,
          1221,
          21521,
          5024,
          11,
          3561,
          261,
          7870,
          4093,
          306,
          3611,
          65,
          42643,
          3244,
          20570,
          2984,
          21428,
          2156,
          1667,
          41903,
          1878,
          1229,
          26261,
          5585,
          89,
          1634,
          13,
          51814
      ]
  },
  {
      "id": 4,
      "avg_logprob": -0.09319894015789032,
      "compression_ratio": 1.3684210777282715,
      "end": 36.0,
      "no_speech_prob": 0.002672910690307617,
      "seek": 2900,
      "start": 29.0,
      "temperature": 0.0,
      "text": " Kasia, odwa\u017cna dziewczynka z bujn\u0105 wyobra\u017ani\u0105, postanowi\u0142a stworzy\u0107 eliksir szcz\u0119\u015bcia.",
      "tokens": [
          50364,
          591,
          25251,
          11,
          3611,
          27111,
          629,
          9758,
          1093,
          6522,
          77,
          2330,
          710,
          758,
          73,
          13113,
          4628,
          24393,
          10659,
          3722,
          1611,
          11,
          2183,
          282,
          24503,
          5024,
          342,
          28321,
          27150,
          806,
          23292,
          347,
          22090,
          1274,
          1788,
          2755,
          13,
          50714
      ]
  },
  {
      "id": 5,
      "avg_logprob": -0.09319894015789032,
      "compression_ratio": 1.3684210777282715,
      "end": 45.0,
      "no_speech_prob": 0.002672910690307617,
      "seek": 2900,
      "start": 36.0,
      "temperature": 0.0,
      "text": " Tomek, jej najlepszy przyjaciel, chcia\u0142 skonstruowa\u0107 lataj\u0105cy dywan, kt\u00f3ry m\u00f3g\u0142by zabra\u0107 ich w podr\u00f3\u017c po Kudolandii.",
      "tokens": [
          50714,
          314,
          423,
          74,
          11,
          28924,
          41903,
          1878,
          1229,
          6501,
          19586,
          1187,
          11,
          26497,
          1221,
          1110,
          4068,
          894,
          11445,
          4465,
          11133,
          1344,
          14584,
          7916,
          11,
          9913,
          275,
          14047,
          34635,
          24838,
          424,
          2162,
          1893,
          261,
          2497,
          11721,
          1427,
          714,
          591,
          532,
          30063,
          5597,
          13,
          51164
      ]
  },
  {
      "id": 6,
      "avg_logprob": -0.09319894015789032,
      "compression_ratio": 1.3684210777282715,
      "end": 49.0,
      "no_speech_prob": 0.002672910690307617,
      "seek": 2900,
      "start": 45.0,
      "temperature": 0.0,
      "text": " Wsp\u00f3lnie zacz\u0119li zbiera\u0107 sk\u0142adniki do swoich projekt\u00f3w.",
      "tokens": [
          51164,
          343,
          4952,
          15741,
          2766,
          34430,
          11052,
          2081,
          710,
          65,
          10609,
          2162,
          1110,
          10358,
          77,
          9850,
          360,
          13291,
          480,
          26261,
          3901,
          13,
          51364
      ]
  },
  {
      "id": 7,
      "avg_logprob": -0.09319894015789032,
      "compression_ratio": 1.3684210777282715,
      "end": 55.0,
      "no_speech_prob": 0.002672910690307617,
      "seek": 2900,
      "start": 49.0,
      "temperature": 0.0,
      "text": " Kasia biega\u0142a po ogrodzie szkolnym, zbieraj\u0105c kolorowe kwiaty i magiczne zio\u0142a.",
      "tokens": [
          51364,
          591,
          25251,
          272,
          414,
          3680,
          5024,
          714,
          5360,
          11452,
          3283,
          7870,
          36620,
          12996,
          11,
          710,
          65,
          811,
          38757,
          17818,
          284,
          6880,
          350,
          6253,
          21398,
          741,
          5585,
          43077,
          710,
          1004,
          5024,
          13,
          51664
      ]
  },
  {
      "id": 8,
      "avg_logprob": -0.08934891223907471,
      "compression_ratio": 1.4444444179534912,
      "end": 60.0,
      "no_speech_prob": 0.4561305642127991,
      "seek": 5500,
      "start": 55.0,
      "temperature": 0.0,
      "text": " Tomek przeszukiwa\u0142 szkoln\u0105 bibliotek\u0119 w poszukiwaniu starych map i zakl\u0119\u0107.",
      "tokens": [
          50364,
          314,
          423,
          74,
          6541,
          10430,
          11788,
          44603,
          7870,
          36620,
          13113,
          34344,
          310,
          916,
          1274,
          261,
          1366,
          89,
          11788,
          86,
          25849,
          342,
          822,
          339,
          4471,
          741,
          23810,
          75,
          1274,
          2162,
          13,
          50614
      ]
  },
  {
      "id": 9,
      "avg_logprob": -0.08934891223907471,
      "compression_ratio": 1.4444444179534912,
      "end": 65.0,
      "no_speech_prob": 0.4561305642127991,
      "seek": 5500,
      "start": 60.0,
      "temperature": 0.0,
      "text": " Gdy nadszed\u0142 dzie\u0144 konkursu, ca\u0142a school zgromadzi\u0142a si\u0119 w auli.",
      "tokens": [
          50614,
          460,
          3173,
          297,
          5834,
          11312,
          1221,
          47568,
          21428,
          2156,
          84,
          11,
          1335,
          5024,
          1395,
          710,
          861,
          298,
          345,
          3992,
          5024,
          3244,
          261,
          257,
          25484,
          13,
          50864
      ]
  },
  {
      "id": 10,
      "avg_logprob": -0.08934891223907471,
      "compression_ratio": 1.4444444179534912,
      "end": 70.0,
      "no_speech_prob": 0.4561305642127991,
      "seek": 5500,
      "start": 65.0,
      "temperature": 0.0,
      "text": " Students jeden po drugim prezentowali swoje projekty.",
      "tokens": [
          50864,
          17244,
          12906,
          714,
          4110,
          332,
          659,
          14185,
          305,
          5103,
          29489,
          447,
          27023,
          874,
          13,
          51114
      ]
  },
  {
      "id": 11,
      "avg_logprob": -0.08934891223907471,
      "compression_ratio": 1.4444444179534912,
      "end": 76.0,
      "no_speech_prob": 0.4561305642127991,
      "seek": 5500,
      "start": 70.0,
      "temperature": 0.0,
      "text": " Kasia z dum\u0105 pokaza\u0142a sw\u00f3j eliksir szcz\u0119\u015bcia, kt\u00f3ry b\u0142yszcza\u0142 w s\u0142o\u0144cu jak diamenty.",
      "tokens": [
          51114,
          591,
          25251,
          710,
          16784,
          1611,
          13010,
          12257,
          5024,
          1693,
          18999,
          806,
          23292,
          347,
          22090,
          1274,
          1788,
          2755,
          11,
          9913,
          272,
          1221,
          20589,
          41524,
          1221,
          261,
          262,
          5249,
          5248,
          12032,
          4207,
          1026,
          2466,
          88,
          13,
          51414
      ]
  },
  {
      "id": 12,
      "avg_logprob": -0.08934891223907471,
      "compression_ratio": 1.4444444179534912,
      "end": 83.0,
      "no_speech_prob": 0.4561305642127991,
      "seek": 5500,
      "start": 76.0,
      "temperature": 0.0,
      "text": " Tomek zaprezentowa\u0142 sw\u00f3j lataj\u0105cy dywan, kt\u00f3ry uni\u00f3s\u0142 si\u0119 w powietrze i wykona\u0142 kilka piruet\u00f3w.",
      "tokens": [
          51414,
          314,
          423,
          74,
          14223,
          265,
          14185,
          30105,
          1693,
          18999,
          4465,
          11133,
          1344,
          14584,
          7916,
          11,
          9913,
          517,
          7138,
          82,
          1221,
          3244,
          261,
          3388,
          1684,
          13503,
          741,
          39287,
          4037,
          1221,
          36466,
          13528,
          15382,
          3901,
          13,
          51764
      ]
  },
  {
      "id": 13,
      "avg_logprob": -0.10953755676746368,
      "compression_ratio": 1.3517786264419556,
      "end": 87.0,
      "no_speech_prob": 0.03208092972636223,
      "seek": 8300,
      "start": 83.0,
      "temperature": 0.0,
      "text": " Wszyscy byli zachwyceni ich pomys\u0142ami.",
      "tokens": [
          50364,
          343,
          15453,
          38966,
          538,
          2081,
          29303,
          9726,
          13037,
          72,
          1893,
          12991,
          39508,
          4526,
          13,
          50564
      ]
  },
  {
      "id": 14,
      "avg_logprob": -0.10953755676746368,
      "compression_ratio": 1.3517786264419556,
      "end": 92.0,
      "no_speech_prob": 0.03208092972636223,
      "seek": 8300,
      "start": 87.0,
      "temperature": 0.0,
      "text": " Eliza u\u015bmiechn\u0119\u0142a si\u0119 i powiedzia\u0142a, wasza kreatywno\u015b\u0107 jest niesamowita.",
      "tokens": [
          50564,
          11991,
          64,
          344,
          1788,
          25210,
          1377,
          1274,
          5024,
          3244,
          741,
          27617,
          25605,
          11,
          390,
          2394,
          350,
          620,
          88,
          20944,
          7753,
          3492,
          48100,
          335,
          305,
          2786,
          13,
          50814
      ]
  },
  {
      "id": 15,
      "avg_logprob": -0.10953755676746368,
      "compression_ratio": 1.3517786264419556,
      "end": 103.0,
      "no_speech_prob": 0.03208092972636223,
      "seek": 8300,
      "start": 92.0,
      "temperature": 0.0,
      "text": " Po zako\u0144czeniu competition uczniowie postanowili wsp\u00f3lnie u\u017cy\u0107 eliksiru happiness i polecie\u0107 na lataj\u0105cym dywanie nad Kudolandi\u0105.",
      "tokens": [
          50814,
          6165,
          710,
          18501,
          5248,
          66,
          39651,
          6211,
          35403,
          3722,
          13998,
          2183,
          282,
          305,
          2312,
          47148,
          2766,
          34097,
          2162,
          806,
          23292,
          347,
          84,
          8324,
          741,
          13208,
          4260,
          2162,
          1667,
          4465,
          11133,
          1344,
          76,
          14584,
          86,
          7155,
          12617,
          591,
          532,
          30063,
          11404,
          13,
          51364
      ]
  },
  {
      "id": 16,
      "avg_logprob": -0.10953755676746368,
      "compression_ratio": 1.3517786264419556,
      "end": 109.0,
      "no_speech_prob": 0.03208092972636223,
      "seek": 8300,
      "start": 103.0,
      "temperature": 0.0,
      "text": " W powietrzu odkryli magiczne krainy pe\u0142ne kolorowych chmur i t\u0119czowych wodospad\u00f3w.",
      "tokens": [
          51364,
          343,
          3388,
          1684,
          81,
          11728,
          3611,
          43298,
          2081,
          5585,
          43077,
          28248,
          3519,
          43205,
          716,
          17818,
          284,
          19605,
          417,
          76,
          374,
          741,
          32489,
          3689,
          19605,
          47751,
          2763,
          345,
          3901,
          13,
          51664
      ]
  },
  {
      "id": 17,
      "avg_logprob": -0.1419629156589508,
      "compression_ratio": 1.0819672346115112,
      "end": 113.0,
      "no_speech_prob": 0.12748588621616364,
      "seek": 10900,
      "start": 110.0,
      "temperature": 0.0,
      "text": " To by\u0142a najwspanialsza przygoda ich \u017cycia.",
      "tokens": [
          50414,
          1407,
          23936,
          11212,
          86,
          4952,
          282,
          12356,
          2394,
          6501,
          70,
          13449,
          1893,
          44343,
          13,
          50564
      ]
  },
  {
      "id": 18,
      "avg_logprob": -0.1419629156589508,
      "compression_ratio": 1.0819672346115112,
      "end": 119.0,
      "no_speech_prob": 0.12748588621616364,
      "seek": 10900,
      "start": 113.0,
      "temperature": 0.0,
      "text": " Od tego day wszyscy wiedzieli, \u017ce magia i przyja\u017a\u0144 mog\u0105 zmienia\u0107 \u015bwiat na lepsze.",
      "tokens": [
          50564,
          12210,
          8627,
          786,
          44232,
          261,
          15338,
          23099,
          11,
          3561,
          2258,
          654,
          741,
          6501,
          2938,
          10659,
          5248,
          34123,
          17020,
          18811,
          2162,
          36425,
          1667,
          476,
          1878,
          1381,
          13,
          50864
      ]
  }
  ];

  const renderContent = (): JSX.Element => {
    return (
      <main style={{paddingBottom: 0}}>
        {renderPreviewImage()}
        {renderTitle()}
        {/* {renderRating()} */}
        {renderTabs()}
        {renderAboutCourse()}
        {renderLessons()}
        {renderReviews()}
        {renderInstructor()}
      </main>
    );
  };

  const renderBottomTabBar = (): JSX.Element => {
    return <components.BottomTabBar />;
  };

  return (
    <>
      {renderImageBackground()}
      {renderHeader()}
      {renderContent()}
      {/* {course.limit === 0 && */}
      
      <BuyFullAccess/>
  {/* } */}
      <TranscriptHighlighter segments={segments} audioSrc={audioSrc} />
      
      {/* {renderBottomTabBar()} */}
    </>
  );
};
