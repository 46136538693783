import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const TwitterSvg: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={21}
      height={18}
      fill='none'
    >
      <g>
        <path
          fill='#55ACEE'
          d='M20.105 2.523a7.793 7.793 0 0 1-.995.362c.402-.454.709-.99.896-1.575a.332.332 0 0 0-.486-.386 7.83 7.83 0 0 1-2.314.915 4.438 4.438 0 0 0-3.091-1.26 4.421 4.421 0 0 0-4.38 4.986 11.306 11.306 0 0 1-7.76-4.116.331.331 0 0 0-.544.042 4.414 4.414 0 0 0-.598 2.22c0 1.065.38 2.074 1.051 2.863a3.74 3.74 0 0 1-.591-.264.332.332 0 0 0-.493.283v.059c0 1.588.854 3.018 2.161 3.798a3.84 3.84 0 0 1-.336-.05.332.332 0 0 0-.378.428 4.415 4.415 0 0 0 3.235 2.96 7.813 7.813 0 0 1-4.176 1.192c-.313 0-.627-.019-.935-.055a.332.332 0 0 0-.218.609 11.91 11.91 0 0 0 6.44 1.887c4.496 0 7.308-2.12 8.876-3.899 1.955-2.217 3.076-5.153 3.076-8.053 0-.122-.002-.244-.005-.366a8.585 8.585 0 0 0 1.976-2.093.332.332 0 0 0-.41-.487Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path
            fill='#fff'
            d='M0 0h20.572v18H0z'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
